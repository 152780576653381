import React from 'react'
import Seo from "../components/seo"
import Layout from "../components/layout"

const ThankYou = () => {

  return(
    <Layout classmain='none'>
      <Seo title="Thank You!" description = "Your information has been submitted and we will get back to you soon." />

      <div className='none__hero'>
        <div className='none__hero-img'>
          <img
              alt='twoj'
              className='none__hero-img--logo'
              src='/images/logo.svg'
          />
        </div>
        <h1 className='none__hero-title htitle'>Thank you for reaching us!</h1>

        <p className='none__hero-descr'>Your information has been submitted and we will get back to you soon.</p>
        <div className='none__hero-buttons'>
          <a className="btn btn--v1" href="/">
              Homepage
          </a>
          <a className="btn btn--v1" href="https://blog.twoj.io/">
              Blog
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou